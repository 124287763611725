import React, {useState} from "react";
const ReactDOM = require("react-dom");

require("preline");
const axios = require("axios");

const FormBtn = ({formId, submit, success, title}) => {
  const [loading, setLoading] = useState(false);

  let click = async () => {
    let form = document.getElementById(formId);
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    setLoading(true);

    let data;
    try {
      data = await submit();
    }
    catch (e) {
      alert("error making request");
      setLoading(false);
      throw e;
    }

    if (data.err) {
      setLoading(false);
      setTimeout(() => {
        alert("Login error: " + data.err);
      }, 500);
    }
    else
      setLoading(success());
  }

  if (loading)
    return <button type="button"
                   className="w-full py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-sm dark:focus:ring-offset-gray-800">
      <span
        className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
        role="status" aria-label="loading"></span>
    </button>
  else
    return <button type="button"
                   onClick={click}
                   className="w-full inline-flex flex-shrink-0 justify-center items-center gap-2 h-[3.375rem] rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-sm  dark:focus:ring-offset-gray-800">
      {title}
    </button>

}

const LoginSubmit = () => {
  let submit = async () => {
    let email = document.getElementById('email').value;
    let password = document.getElementById('password').value;

    return (await axios.post("/login", { email, password })).data;
  }

  let success = () => {
    window.location.reload();
    return true;
  }

  return <FormBtn title="Login" formId='login-form' submit={submit} success={success} />
}

ReactDOM.render(<LoginSubmit />, document.getElementById('login-submit'));




const ContactSubmit = () => {
  let submit = async () => {
    let name = document.getElementById('contact-name').value;
    let email = document.getElementById('contact-email').value;
    let msg = document.getElementById('contact-msg').value;

    return (await axios.post("/contact", { email, name, msg })).data;
  }

  let success = () => {
    alert("Message Sent!")
    return false;
  }

  return <FormBtn title="Send" formId='contact-form' submit={submit} success={success} />
}

ReactDOM.render(<ContactSubmit />, document.getElementById('contact-submit'));
